<template>
  <base-layout-two
    :page-title="`Payment Method`"
    :page-default-back-link="`/users/${$route.params.id}`"
  >
    <section class="ion-padding-start ion-padding-top">
      <h1>Edit Payment Method</h1>
    </section>

    <section class="ion-padding">
      <v-form @submit="onSubmit">
        <ion-grid>
          <ion-row>
            <!-- Name -->
            <ion-col size="12">
              <base-input label-text="Name">
                <v-field
                  name="name"
                  v-slot="{ field }"
                  v-model="payment_method.name"
                  :rules="required"
                >
                  <ion-input
                    name="name"
                    v-bind="field"
                    placeholder="Name as it appears on card"
                    autocapitalize="words"
                    type="text"
                  ></ion-input>
                </v-field>
              </base-input>
              <v-error-message
                name="name"
                class="error-message"
              ></v-error-message>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-button class="regular-button" expand="block" type="submit"
          >Save</ion-button
        >
      </v-form>
    </section>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonButton } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      user: {},
      payment_method: {},
      required: yup.string().required(),
    };
  },

  ionViewWillEnter() {
    // this.fetchPaymentMethod();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchPaymentMethod() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.$route.params.id}`)
        .then((response) => {
          this.user = response.data.user;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API}/transactions`,
        data: this.payment_method,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.payment_method = response.data.payment_method;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({
            message: "Payment Method Created",
            color: "secondary",
          });
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>